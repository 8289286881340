import { Divider, ListItemText, MenuItem } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PATH_AUTH, PATH_DASHBOARD } from "../../../routes/paths";
import { IconButtonAnimate } from "../../../ui/animate";
import { CustomAvatar } from "../../../ui/custom-avatar";
import MenuPopover from "../../../ui/menu-popover";
import { useSnackbar } from "../../../ui/snackbar";

export default function AccountPopover() {
  const navigate = useNavigate();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar alt={user?.fullName} name={user?.fullName} />
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
      >
        <MenuItem
          sx={{ m: 1 }}
          onClick={() => {
            navigate(PATH_DASHBOARD.general.summary);
          }}
        >
          <ListItemText
            secondaryTypographyProps={{
              sx: { textOverflow: "ellipsis", overflow: "hidden" },
            }}
            primary={user?.fullName}
            secondary={user?.email}
          ></ListItemText>
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
