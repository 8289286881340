// emotion
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
// rtl

type Props = {
  children: React.ReactNode;
};

export default function ThemeRtlLayout({ children }: Props) {
  const theme = useTheme();

  useEffect(() => {
    document.dir = theme.direction;
  }, [theme.direction]);

  const cacheRtl = createCache({
    key: theme.direction === "rtl" ? "rtl" : "css",
  });

  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
