import { Player } from "@lordicon/react";
import React, { useEffect, useRef, useState } from "react";

const HoverableAnimatedWrapper = ({
  children,
  setIsHovered,
}: {
  children: React.ReactNode;
  setIsHovered: (val: boolean) => void;
}) => {
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </div>
  );
};

const AnimatedIcon = ({
  icon,
  isPlaying,
  animateOnHover,
  ...props
}: {
  icon: unknown;
  isPlaying?: boolean;
  animateOnHover?: boolean;
} & Player["props"]) => {
  const playerRef = useRef<Player>(null);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    playerRef.current?.play();
  }, []);

  useEffect(() => {
    if (isPlaying || isHovered) playerRef.current?.play();
  }, [isPlaying, isHovered]);

  const Wrapper = animateOnHover ? HoverableAnimatedWrapper : React.Fragment;

  return (
    <Wrapper setIsHovered={setIsHovered}>
      <Player
        ref={playerRef}
        icon={icon}
        colorize="currentColor"
        onComplete={() => {
          if (isPlaying || isHovered) {
            playerRef.current?.playFromBeginning();
          }
        }}
        {...props}
      />
    </Wrapper>
  );
};

export default AnimatedIcon;
