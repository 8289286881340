import axios from "axios";

import { HOST_API_KEY } from "../config-global";

const axiosInstance = axios.create({
  baseURL: HOST_API_KEY,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject<{ message: string; statusCode: number }>(
      error || "Something went wrong"
    )
);

export default axiosInstance;
