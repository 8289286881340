export * from "@prisma/client";

export enum ROLE {
  EMPLOYEE = "EMPLOYEE",
  MANAGER = "MANAGER",
  ADMIN = "ADMIN",
  HR = "HR",
}

export enum QUESTION_CATEGORY {
  VISION = "VISION",
  COMMUNICATION = "COMMUNICATION",
  DEVELOPMENT = "DEVELOPMENT",
  TRUST = "TRUST",
  PROBLEM_SOLVING = "PROBLEM SOLVING",
  CARE = "CARE",
  COACH = "COACH",
  DEVELOP = "DEVELOP",
  DELIVER = "DELIVER",
  LEAD = "LEAD",
}

export enum QUESTION_TYPE {
  LIKE = "LIKE",
  RATING = "RATING",
  KEYWORDS = "KEYWORDS",
  TEXT = "TEXT",
}

export const SURVEY_ID = "fd48a0a2-d2aa-4c46-b837-a660930dfa74";
export const SELF_SURVEY_ID = "b277ee1f-1542-4c53-8110-1790615af0ba";
