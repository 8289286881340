import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import {
  GoalActionStatusWithAction,
  GoalWithObjective,
} from "components/types";
import { ActionStatus, Goal } from "database";
import React from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_DASHBOARD } from "routes/paths";
import useAsyncEffect from "use-async-effect";
import axios from "utils/axios";

import { useLocales } from "../../locales";

export const GoalTrackPage = () => {
  const { id } = useParams();
  const [goal, setGoal] = React.useState<GoalWithObjective>();
  const [actionStatuses, setActionStatuses] =
    React.useState<GoalActionStatusWithAction[]>();

  useAsyncEffect(async () => {
    const { data } = await axios.get<GoalWithObjective>(`/goals/find/${id}`);

    const { data: actions } = await axios.get<GoalActionStatusWithAction[]>(
      `/goals/actionStatuses/${id}`
    );
    setActionStatuses(actions);
    setGoal(data);
  }, []);

  const navigate = useNavigate();
  const { translate } = useLocales();
  const { currentLang } = useLocales();

  if (!goal) {
    return null;
  }

  const isComplete =
    actionStatuses?.filter((aS) => aS.status === ActionStatus.DONE).length ===
    actionStatuses?.length;

  // if (goal.dateCompleted) {
  //   navigate(PATH_DASHBOARD.general.goals);
  // }

  const goalObjective =
    currentLang.value === "fr"
      ? goal.GoalObjective.textFR
      : goal.GoalObjective.text;

  return (
    <Box>
      <Box display="flex">
        <Card sx={{ p: 3, maxWidth: "320px", mb: 4, ml: 4 }}>
          <Typography variant="h5" color="secondary" mb={2}>
            {goalObjective}
          </Typography>
          <Typography color="primary" variant="h5" mt={2} mb={2}>
            <Typography color="secondary">{`${translate(
              "goals_page.target"
            )}`}</Typography>
            {new Intl.DateTimeFormat(window.navigator.language).format(
              new Date(goal.targetDate)
            )}
            {goal.dateCompleted && (
              <Typography color="primary">
                <Typography component="span" color="secondary">
                  {`${translate("goals_page.completed")}`}
                  <br />
                </Typography>
                {new Intl.DateTimeFormat(window.navigator.language).format(
                  new Date(goal.dateCompleted)
                )}
              </Typography>
            )}
          </Typography>

          <Button
            disabled={!isComplete || !!goal.dateCompleted}
            size="large"
            color="secondary"
            variant="contained"
            onClick={async () => {
              await axios.patch<Goal>(`/goals/${id}`);

              navigate(PATH_DASHBOARD.general.goals);
            }}
          >
            {`${translate("goals_page.complete")}`}
          </Button>
        </Card>
      </Box>
      <DndProvider backend={HTML5Backend}>
        <Box maxWidth="lg">
          <Grid container spacing={2}>
            <GridItem
              status={ActionStatus.TODO}
              accept={[ActionStatus.IN_PROGRESS, ActionStatus.DONE]}
              title={`${translate("goals_page.todo")}`}
            >
              {actionStatuses
                ?.filter((aS) => aS.status === ActionStatus.TODO)
                .map((card) => {
                  return (
                    <KanbanCard
                      canDrag={!goal.dateCompleted}
                      onChange={async () => {
                        const { data: actions } = await axios.get<
                          GoalActionStatusWithAction[]
                        >(`/goals/actionStatuses/${id}`);
                        setActionStatuses(actions);
                      }}
                      actionStatus={card}
                      key={card.id}
                    >
                      {currentLang.value === "fr"
                        ? card.GoalAction.textFR
                        : card.GoalAction.text}
                    </KanbanCard>
                  );
                })}
            </GridItem>
            <GridItem
              status={ActionStatus.IN_PROGRESS}
              accept={[ActionStatus.TODO, ActionStatus.DONE]}
              title={`${translate("goals_page.inprogress")}`}
            >
              {actionStatuses
                ?.filter((aS) => aS.status === ActionStatus.IN_PROGRESS)
                .map((card) => {
                  return (
                    <KanbanCard
                      canDrag={!goal.dateCompleted}
                      onChange={async () => {
                        const { data: actions } = await axios.get<
                          GoalActionStatusWithAction[]
                        >(`/goals/actionStatuses/${id}`);
                        setActionStatuses(actions);
                      }}
                      actionStatus={card}
                      key={card.id}
                    >
                      {currentLang.value === "fr"
                        ? card.GoalAction.textFR
                        : card.GoalAction.text}
                    </KanbanCard>
                  );
                })}
            </GridItem>
            <GridItem
              status={ActionStatus.DONE}
              title={`${translate("goals_page.done")}`}
              accept={[ActionStatus.IN_PROGRESS, ActionStatus.TODO]}
            >
              {actionStatuses
                ?.filter((aS) => aS.status === ActionStatus.DONE)
                .map((card) => {
                  return (
                    <KanbanCard
                      canDrag={!goal.dateCompleted}
                      onChange={async () => {
                        const { data: actions } = await axios.get<
                          GoalActionStatusWithAction[]
                        >(`/goals/actionStatuses/${id}`);
                        setActionStatuses(actions);
                      }}
                      actionStatus={card}
                      key={card.id}
                    >
                      {currentLang.value === "fr"
                        ? card.GoalAction.textFR
                        : card.GoalAction.text}
                    </KanbanCard>
                  );
                })}
            </GridItem>
          </Grid>
        </Box>
      </DndProvider>
    </Box>
  );
};

type GridItemProps = {
  title: React.ReactNode;
  children: React.ReactNode;
  accept: Array<ActionStatus>;
  status: ActionStatus;
};

const GridItem = ({ title, children, accept, status }: GridItemProps) => {
  const [, dropRef] = useDrop<GoalActionStatusWithAction>(() => ({
    accept,
    drop: () => {
      return { status };
    },
  }));

  return (
    <Grid item xs={4}>
      <Box ref={dropRef} height="calc(100vh - 200px)" sx={{ p: 2 }}>
        <Box display="flex" justifyContent="center">
          <Typography mb={2} color="secondary" variant="h5">
            {title}
          </Typography>
        </Box>
        <Stack spacing={2}>{children}</Stack>
      </Box>
    </Grid>
  );
};

type KanbanCardProps = {
  children: React.ReactNode;
  actionStatus: GoalActionStatusWithAction;
  onChange: () => void;
  canDrag?: boolean;
};

const KanbanCard = ({
  children,
  actionStatus,
  onChange,
  canDrag = true,
}: KanbanCardProps) => {
  const [{ opacity }, dragRef] = useDrag(
    () => ({
      canDrag: () => {
        return !!canDrag;
      },
      type: actionStatus.status,
      item: actionStatus,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
      end: async (item, monitor) => {
        if (monitor.didDrop()) {
          await axios.patch(`/goals/actionStatuses/${item.id}`, {
            status: (monitor.getDropResult() as { status: ActionStatus })
              .status,
          });
          onChange();
        }
      },
    }),
    []
  );

  return (
    <Card ref={dragRef} sx={{ p: 4, cursor: "pointer", opacity }}>
      {children}
    </Card>
  );
};
