import { Box, Drawer, Stack } from "@mui/material";
import AnimatedLogo from "assets/icons/AnimatedLogo";
import { useAuthContext } from "auth/useAuthContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { NAV } from "../../../config-global";
import useResponsive from "../../../hooks/useResponsive";
// import Logo from "../../../ui/logo";
import { NavSectionVertical } from "../../../ui/nav-section";
import Scrollbar from "../../../ui/scrollbar";
import navConfig from "./config-navigation";
import NavAccount from "./NavAccount";
import NavDocs from "./NavDocs";
import NavLegal from "./NavLegal";

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");
  // get user details and type to update the menu

  const { user } = useAuthContext();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!user) {
    return null;
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <AnimatedLogo />
        {/* <Logo /> */}
      </Stack>

      {/* Add here customization based on Value from before for the menu switch */}
      <NavSectionVertical data={navConfig({ roles: user?.roles })} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="column" gap={4}>
        <NavDocs />
        <NavAccount />
        <NavLegal />
      </Stack>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: "transparent",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
