import { Card, CardContent, Stack, Typography } from "@mui/material";

import LoginLayout from "../../layouts/login";
import Logo from "../../ui/logo";
import AuthLoginForm from "./AuthLoginForm";
//import AuthWithSocial from "./AuthWithSocial";

export default function Login() {
  return (
    <LoginLayout>
      <Stack sx={{ width: 1, alignItems: "center" }}>
        <Logo
          src="/logo/logo_full.svg"
          sx={{
            width: 300,
            height: "inherit",
          }}
        />

        <Card sx={{ mt: 4, width: 437 }}>
          <CardContent>
            <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
              <Typography
                align="center"
                sx={{ color: "secondary" }}
                variant="h4"
              >
                Sign in
              </Typography>

              {/* <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link
            component={RouterLink}
            to={PATH_AUTH.register}
            variant="subtitle2"
          >
            Create an account
          </Link>
        </Stack> */}
            </Stack>

            {/* <Alert severity="info" sx={{ mb: 3 }}>
        Use email : <strong>demo@minimals.cc</strong> / password :
        <strong> demo1234</strong>
      </Alert> */}

            <AuthLoginForm />
            {/*<AuthWithSocial />*/}
          </CardContent>
        </Card>
      </Stack>
    </LoginLayout>
  );
}
