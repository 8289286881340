// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Box, Link, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

//import { Link as RouterLink } from "react-router-dom";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { PATH_DASHBOARD } from "../../../routes/paths";
import { CustomAvatar } from "../../../ui/custom-avatar";

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function NavAccount() {
  const { user } = useAuthContext();
  return (
    <Link underline="none" color="inherit" px={2.5}>
      <StyledRoot>
        <CustomAvatar alt={user?.fullName} name={user?.fullName} />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.fullName}
          </Typography>

          <Typography
            variant="body2"
            noWrap
            sx={{ color: "text.secondary", textTransform: "lowercase" }}
          >
            {user?.roles.join(", ")}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
