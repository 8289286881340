import { PATH_AFTER_LOGIN } from "config-global";
import { Navigate } from "react-router-dom";

import LoadingScreen from "../ui/loading-screen";
import { useAuthContext } from "./useAuthContext";

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to={PATH_AFTER_LOGIN} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
