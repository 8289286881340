import { Box, Link as MuiLink } from "@mui/material";
import { useLocales } from "locales";
import { Link, matchPath, useLocation } from "react-router-dom";

import { PATH_DASHBOARD } from "../../../routes/paths";

export default function NavLegal() {
  const { pathname } = useLocation();

  const { translate } = useLocales();

  return (
    <Box
      px={2}
      pb={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <MuiLink
        underline={
          matchPath(
            {
              path: PATH_DASHBOARD.general.starLeaderPrivacyPolicy,
              end: true,
            },
            pathname
          )
            ? "always"
            : "none"
        }
        color="text.primary"
        variant="caption"
        component={Link}
        to={PATH_DASHBOARD.general.starLeaderPrivacyPolicy}
      >
        {`${translate("docs.privacyPolicy")}`}
      </MuiLink>

      <MuiLink
        variant="caption"
        color="text.primary"
        underline={
          matchPath(
            {
              path: PATH_DASHBOARD.general.starLeaderCookiePolicy,
              end: true,
            },
            pathname
          )
            ? "always"
            : "none"
        }
        component={Link}
        to={PATH_DASHBOARD.general.starLeaderCookiePolicy}
      >
        {`${translate("docs.cookiePolicy")}`}
      </MuiLink>

      <MuiLink
        color="text.primary"
        variant="caption"
        underline={
          matchPath(
            {
              path: PATH_DASHBOARD.general.starLeaderLegalNotice,
              end: true,
            },
            pathname
          )
            ? "always"
            : "none"
        }
        component={Link}
        to={PATH_DASHBOARD.general.starLeaderLegalNotice}
      >
        {`${translate("docs.legalNotice")}`}
      </MuiLink>
    </Box>
  );
}
