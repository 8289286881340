import { Box, Stack } from "@mui/material";
import { useAuthContext } from "auth/useAuthContext";

import { NAV } from "../../../config-global";
import Logo from "../../../ui/logo";
import { NavSectionMini } from "../../../ui/nav-section";
import { hideScrollbarX } from "../../../utils/cssStyles";
import navConfig from "./config-navigation";
import NavToggleButton from "./NavToggleButton";

export default function NavMini() {
  const { user } = useAuthContext();

  if (!user) {
    return null;
  }

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 22,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: "fixed",
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: "auto", my: 2 }} />

        <NavSectionMini data={navConfig({ roles: user.roles })} />
      </Stack>
    </Box>
  );
}
