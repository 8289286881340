import { Box, Link as MuiLink, Stack } from "@mui/material";
import { Link, matchPath, useLocation } from "react-router-dom";
import palette from "theme/palette";

import { useLocales } from "../../../locales";
import { PATH_DASHBOARD } from "../../../routes/paths";

declare const openAxeptioCookies: () => void;

export default function NavDocs() {
  const { pathname } = useLocation();

  const { translate } = useLocales();

  const textColor = palette("light").secondary.dark;

  return (
    <Stack
      spacing={3}
      sx={{
        px: 5,
        width: 1,
        display: "block",
        textAlign: "center",
      }}
    >
      <Box display="flex" flexDirection="column" gap={2} textAlign="left">
        <MuiLink
          color={textColor}
          to={`${PATH_DASHBOARD.general.starLeaderValues}`}
          component={Link}
          variant="body2"
          underline={
            matchPath(
              {
                path: PATH_DASHBOARD.general.starLeaderValues,
                end: true,
              },
              pathname
            )
              ? "always"
              : "hover"
          }
        >
          {`${translate("docs.starleader_values")}`}
        </MuiLink>

        <MuiLink
          color={textColor}
          to="mailto:support@starleader.io"
          component={Link}
          variant="body2"
        >
          {`${translate("docs.contact_us")}`}
        </MuiLink>

        <MuiLink
          color={textColor}
          onClick={() => openAxeptioCookies?.()}
          variant="body2"
        >
          {`${translate("docs.update_your_cookies_settings")}`}
        </MuiLink>
      </Box>
    </Stack>
  );
}
