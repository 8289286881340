/* eslint-disable camelcase */

import analyticsIcon from "assets/icons/lordicon/analytics.json";
import campaignsIcon from "assets/icons/lordicon/campaigns.json";
import goalsIcon from "assets/icons/lordicon/goals.json";
import homeIcon from "assets/icons/lordicon/home.json";
import reviewIcon from "assets/icons/lordicon/review.json";
import summaryIcon from "assets/icons/lordicon/summary.json";
import { Role } from "database";
import AnimatedIcon from "ui/animate/AnimatedIcon";

import { useLocales } from "../../../locales";
import { PATH_DASHBOARD } from "../../../routes/paths";
import SvgColor from "../../../ui/svg-color";

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dashboard: icon("ic_dashboard"),
  user: icon("ic_user"),
  chat: icon("ic_chat"),
  menu_item: icon("ic_menu_item"),
  folder: icon("ic_folder"),
  file: <AnimatedIcon icon={reviewIcon} animateOnHover />,
  kanban: <AnimatedIcon icon={goalsIcon} animateOnHover />,
  label: icon("ic_label"),
  cart: icon("ic_cart"),
  analytics: <AnimatedIcon icon={analyticsIcon} animateOnHover />,
  external: icon("ic_external"),
  blog: icon("ic_blog"),
  mail: icon("ic_mail"),
  banking: <AnimatedIcon icon={homeIcon} animateOnHover />,
  campaigns: <AnimatedIcon icon={campaignsIcon} animateOnHover />,
  summary: <AnimatedIcon icon={summaryIcon} animateOnHover />,
};

const NavConfig = ({ roles }: { roles: Role[] }) => {
  const isEmployee = roles.includes(Role.EMPLOYEE);
  const isManager = roles.includes(Role.MANAGER);
  const isHr = roles.includes(Role.HR);
  const isAdmin = roles.includes(Role.ADMIN);

  const { translate } = useLocales();

  const menu = [];

  if (isEmployee || isManager || isHr) {
    menu.push({
      title: `${translate("menu.Home")}`,
      path: PATH_DASHBOARD.general.app,
      icon: ICONS.banking,
    });
  }

  if (isEmployee || isManager) {
    menu.push({
      title: `${translate("menu.Reviews")}`,
      path: PATH_DASHBOARD.general.reviews,
      icon: ICONS.file,
    });
  }

  if (isManager) {
    menu.push({
      title: `${translate("menu.Summary")}`,
      path: PATH_DASHBOARD.general.summary,
      icon: ICONS.summary,
    });
  }

  if (isManager) {
    menu.push({
      title: `${translate("menu.Goals")}`,
      path: PATH_DASHBOARD.general.goals,
      icon: ICONS.kanban,
    });
  }

  if (isHr || isEmployee || isManager) {
    menu.push({
      title: `${translate("menu.Campaigns")}`,
      path: PATH_DASHBOARD.general.campaigns,
      icon: ICONS.campaigns,
    });
  }

  if (isHr) {
    menu.push({
      title: `${translate("menu.Analytics")}`,
      path: PATH_DASHBOARD.general.companyStats,
      icon: ICONS.analytics,
    });
  }

  if (isAdmin) {
    menu.push({
      title: `${translate("menu.Admin")}`,
      path: PATH_DASHBOARD.general.admin,
      icon: ICONS.user,
    });
  }

  return [
    {
      subheader: "",
      items: menu,
    },
  ];
};
export default NavConfig;
