import { Box, BoxProps } from "@mui/material";
import { forwardRef } from "react";
//import { Link as RouterLink } from "react-router-dom";

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  src?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  (
    { disabledLink = false, sx, src = "/logo/logo_single.svg", ..._other },
    _ref
  ) => {
    const logo = (
      <Box
        component="img"
        src={src}
        sx={{
          width: 178,
          height: 45,
          //cursor: "pointer",
          ...sx,
        }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return logo; //<Link sx={{ display: "contents" }}>{logo}</Link>;
  }
);

export default Logo;
