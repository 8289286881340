import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactGA.initialize("G-2EBCTTFNEJ");

const tagManagerArgs = {
  gtmId: "GTM-P95BT27",
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

Sentry.init({
  dsn: "https://7d22093518f197434f51671f350dff24@o4505882500136960.ingest.sentry.io/4505882518355968",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.NODE_ENV,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
