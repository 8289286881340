import "./locales/i18n";
import "simplebar/src/simplebar.css";
import "react-lazy-load-image-component/src/effects/blur.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
// import { AuthProvider } from './auth/Auth0Context';
// import { AuthProvider } from './auth/FirebaseContext';
// import { AuthProvider } from './auth/AwsCognitoContext';
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./auth/JwtContext";
import ThemeLocalization from "./locales";
import Router from "./routes";
import ThemeProvider from "./theme";
import { MotionLazyContainer } from "./ui/animate";
import { SettingsProvider, ThemeSettings } from "./ui/settings";
import SnackbarProvider from "./ui/snackbar";

dayjs.extend(isBetweenPlugin);
dayjs.extend(quarterOfYear);

const queryClient = new QueryClient();
TimeAgo.addDefaultLocale(en);
export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <SettingsProvider>
            <BrowserRouter>
              <MotionLazyContainer>
                <ThemeProvider>
                  <ThemeSettings>
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <QueryClientProvider client={queryClient}>
                          <Router />
                        </QueryClientProvider>
                      </SnackbarProvider>
                    </ThemeLocalization>
                  </ThemeSettings>
                </ThemeProvider>
              </MotionLazyContainer>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
