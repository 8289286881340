import { Container } from "@mui/material";

import { StyledRoot } from "./styles";

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function LoginLayout({ children }: Props) {
  return (
    <StyledRoot>
      <Container
        sx={{
          my: 10,
          display: "flex",
          justifyContent: "center",
          height: "fit-content",
        }}
      >
        {children}
      </Container>
    </StyledRoot>
  );
}
