// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { arSA, enUS, frFR, viVN, zhCN } from "@mui/material/locale";

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.

export const allLangs = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "/assets/icons/flags/ic_flag_en.svg",
  },
  // {
  //   label: "Français",
  //   value: "fr",
  //   systemValue: frFR,
  //   icon: "/assets/icons/flags/ic_flag_fr.svg",
  // },
  // {
  //   label: "Vietnamese",
  //   value: "vi",
  //   systemValue: viVN,
  //   icon: "/assets/icons/flags/ic_flag_vn.svg",
  // },
  // {
  //   label: "Chinese",
  //   value: "cn",
  //   systemValue: zhCN,
  //   icon: "/assets/icons/flags/ic_flag_cn.svg",
  // },
  // {
  //   label: "Arabic (Sudan)",
  //   value: "ar",
  //   systemValue: arSA,
  //   icon: "/assets/icons/flags/ic_flag_sa.svg",
  // },
];

export const defaultLang = allLangs[0]; // English, Français
