//import { ArrowBack } from "@mui/icons-material";
import { AppBar, Box, IconButton, Stack, Toolbar } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useAuthContext } from "auth/useAuthContext";
import { useLocation } from "react-router-dom";

import { HEADER, NAV } from "../../../config-global";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import navConfig from "../../../layouts/dashboard/nav/config-navigation";
import Iconify from "../../../ui/iconify";
import Logo from "../../../ui/logo";
import { useSettingsContext } from "../../../ui/settings";
import { bgBlur } from "../../../utils/cssStyles";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
//import NotificationsPopover from "./NotificationsPopover";

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();
  const { user } = useAuthContext();

  //const navigate = useNavigate();

  const { themeLayout, toolbarPortalRef } = useSettingsContext();

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const location = useLocation();

  const isRootPage = navConfig({
    roles: user?.roles || [],
  })[0]
    .items?.map((a) => a.path)
    .includes(location.pathname);

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <LanguagePopover />

        {/*<NotificationsPopover />*/}

        {/*<ContactsPopover /> */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {!isRootPage && (
          <Box ref={toolbarPortalRef}>
            {/* <Box width="100%">
              <Fab
                variant="soft"
                size="small"
                color="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack />
              </Fab>
            </Box> */}
          </Box>
        )}
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
